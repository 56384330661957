
		import Async from "../../../node_modules/@preact/async-loader/async.js";

		function load(cb) {
			require.ensure([], function (require) {
				cb( require("!!../../../node_modules/babel-loader/lib/index.js??ref--4!./index.tsx") );
			}, "route-camera-error/index.tsx");
		}

		export default Async(load);
	